import React from 'react'

import { GridWrapper } from './styles'
import { IPageBuilderSection, TDevice } from '../../types'
import CardSection from '../CardSection'
import { PageBuilderSection } from '../../components/PageBuilderSection'
import { DeviceControl } from '../../styles'
import { TGridColumns, mapColumns } from './types'

export interface GridSectionProps {
  data: {
    columns: TGridColumns
    items: IPageBuilderSection[]
    device?: TDevice
  }
}

const GridSection: React.FC<GridSectionProps> = props => {
  const { items, columns, device } = props.data

  const gridColums = mapColumns[columns] || '1fr 1fr'

  return (
    <DeviceControl $device={device}>
      <GridWrapper className="grid-section" $gridColums={gridColums} $gridCMSColumns={columns}>
        {items?.map(section => {
          return (
            <PageBuilderSection
              key={section.id}
              noPadding
              overlay
              section={CardSection}
              data={section}
            />
          )
        })}
      </GridWrapper>
    </DeviceControl>
  )
}

export default GridSection
