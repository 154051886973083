import styled, { css } from 'styled-components'
import { TDevice } from './types'

export const PageWrapper = styled.div``

export const PageContent = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 5px;
  overflow: hidden;
  padding-bottom: 70px;

  --text-color: #fff;

  color: var(--text-color);

  a {
    color: var(--text-color);
    text-decoration: none;
  }

  span {
    color: var(--text-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 10px;
    color: var(--text-color);

    ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
      margin: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    gap: 20px;
    padding-bottom: 20px;
  }
`

interface IDeviceProps {
  $device?: TDevice
}

export const DeviceControl = styled.div<IDeviceProps>`
  display: contents;
  ${({ $device }) =>
    $device === 'mobile' &&
    css`
      display: none;
    `}

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    ${({ $device }) =>
      $device === 'desktop'
        ? css`
            display: none;
          `
        : css`
            display: contents;
          `}
  }
`
