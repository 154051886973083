import React, { useMemo } from 'react'

import { BrandImage, BrandsWrapper } from './styles'
import ResposiveImage, { IMedia } from '../../../ResposiveImage'

export interface BrandsSectionProps {
  data: {
    items: IBrandMedia[]
  }
}

interface IBrandMedia {
  id: string
  image: IMedia
  name: string
}

const BrandsSection: React.FC<BrandsSectionProps> = props => {
  const { items } = props.data

  return (
    <BrandsWrapper className="brands-section">
      {items?.map(brand => {
        return (
          <BrandImage key={brand.id}>
            <ResposiveImage key={brand.id} desktop={brand.image} />
          </BrandImage>
        )
      })}
    </BrandsWrapper>
  )
}

export default BrandsSection
