import React from 'react'
import CardSection, { CardSectionProps } from '../CardSection'
import { Wrapper } from './styles'

const HeroSection: React.FC<CardSectionProps> = props => {
  return (
    <Wrapper className="hero-section">
      <CardSection {...props} />
    </Wrapper>
  )
}

export default HeroSection
