import React from 'react'

import { SliderWrapper } from './styles'
import { IPageBuilderSection, TDevice } from '../../types'
import CardSection from '../CardSection'
import { PageBuilderSection } from '../../components/PageBuilderSection'
import Slider, { CustomArrowProps } from 'react-slick'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import { DeviceControl } from '../../styles'

export interface SliderSectionProps {
  data: {
    items: IPageBuilderSection[]
    device?: TDevice
  }
}

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <div {...props}>
    <SliderArrowCustom position="right" />
  </div>
)

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <div {...props}>
    <SliderArrowCustom />
  </div>
)

const SliderSection: React.FC<SliderSectionProps> = props => {
  const { items, device } = props.data

  return (
    <DeviceControl $device={device}>
      <SliderWrapper className="slider-section">
        <Slider
          dots={false}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {items?.map(section => {
            return (
              <PageBuilderSection key={section.id} noPadding section={CardSection} data={section} />
            )
          })}
        </Slider>
      </SliderWrapper>
    </DeviceControl>
  )
}

export default SliderSection
