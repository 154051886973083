import styled, { css } from 'styled-components'

export type ColorTheme = 'light' | 'dark' | 'dark/light' | 'light/dark'
interface Props {
  $overlay?: boolean
  $noPadding?: boolean
  $theme?: ColorTheme
}

export const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 2;
  opacity: 0.09;
  transition: opacity, background-color 0.2s ease-in-out;
  background-color: #000;
  pointer-events: none;
`

export const OverlayHover = css`
  &:hover {
    ${Overlay} {
      background-color: #00233c;
      opacity: 0.3;
      &.bronze {
        background-color: #231610;
      }
    }
  }
`

export const OverlayBronzeHover = css`
  &:hover {
    ${Overlay} {
      background-color: #231610;
      opacity: 0.3;
    }
  }
`

export const Wrapper = styled.div<Props>`
  position: relative;
  width: 100%;
  margin: 0 auto;
  white-space: pre-line;



  ${({ $theme }) => {
    switch ($theme) {
      case 'light':
        return css`
          .card-title > * {
            color: #fff;
          }
          .card-description > * {
            color: #fff;
          }
        `
      case 'dark':
        return css`
          .card-title > * {
            color: #000;
          }
          .card-description > * {
            color: #000;
          }
        `
      case 'dark/light':
        return css`
          .card-title > * {
            color: #000;
          }
          .card-description > * {
            color: #fff;
          }
        `
      case 'light/dark':
        return css`
          .card-title > * {
            color: #fff;
          }
          .card-description > * {
            color: #000;
          }
        `
      default:
        return css``
    }
  }}

  ${({ $noPadding }) =>
    !$noPadding &&
    css`
      padding-left: 5px;
      padding-right: 5px;

      ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
        padding-left: 5px;
        padding-right: 5px;
      }
    `}

  ${({ $overlay }) =>
    !$overlay &&
    css`
      ${Overlay} {
        display: none !important;
      }
    `}
`
