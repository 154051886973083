import React from 'react'
import { RiCircleLine, RiCircleFill } from '@remixicon/react'
import { IPageBuilderSection } from '../../types'
import { InnerDot, SliderWrapper, WrapperDots } from './styles'
import Slider, { CustomArrowProps } from 'react-slick'
import HeroSection from '../../sections/HeroSection'
import { PageBuilderSection } from '../PageBuilderSection'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import useMediaQuery from '~/hooks/use-media-query'

interface HeroSliderProps {
  items: IPageBuilderSection[]
}

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <SliderArrowCustom position="right" {...props} />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <SliderArrowCustom {...props} />
)

const Dot = () => {
  return (
    <InnerDot>
      <RiCircleLine className="inactive-dot" />
      <RiCircleFill className="active-dot" />
    </InnerDot>
  )
}

const HeroSlider: React.FC<HeroSliderProps> = ({ items }) => {
  const isMobile = useMediaQuery({
    query: breakpoints => `(max-width: ${breakpoints[2]})`,
  })
  const _items = items
    .map(item => ({ ...item, overlay: item?.gradientOverlay || 'blue' }))
    .filter(item =>
      item?.device ? (isMobile ? item?.device === 'mobile' : item?.device === 'desktop') : true
    )

  return (
    <SliderWrapper className="slider-section">
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        autoplay={true}
        autoplaySpeed={3000}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        appendDots={dots => <WrapperDots>{dots}</WrapperDots>}
        customPaging={i => <Dot />}
      >
        {_items?.map(section => {
          return (
            <PageBuilderSection key={section.id} noPadding section={HeroSection} data={section} />
          )
        })}
        {/* <PageBuilderSection key={_items[0].id} noPadding section={HeroSection} data={_items[1]} /> */}
      </Slider>
    </SliderWrapper>
  )
}

export default HeroSlider
