// @ts-ignore
import { Box } from 'theme-ui'
import React, { FC, SVGProps } from 'react'

enum Direction {
  'up' = 'up',
  'down' = 'down',
  'left' = 'left',
  'right' = 'right',
}

type Props = SVGProps<SVGSVGElement> & {
  color?: string
  direction?: Direction | string
}

const rotate = (direction: Direction) => {
  switch (direction) {
    case 'right':
      return `rotate(90deg)`
    case 'down':
      return `rotate(180deg)`
    case 'left':
      return `rotate(-90deg)`
    case 'up':
    default:
      return 'none'
  }
}

const Chevron: FC<Props> = ({ color = 'currentColor', direction = Direction.up, ...rest }) => {
  return (
    <Box
      as="svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      sx={{
        transition: 'transform 0.3s ease',
        // @ts-ignore
        transform: rotate(direction),
      }}
      {...rest}
    >
      <path
        d="M12 11.7733L7.05 16.7233L5.636 15.3093L12 8.94531L18.364 15.3093L16.95 16.7233L12 11.7733Z"
        fill={color || 'currentColor'}
      />
    </Box>
  )
}

export default Chevron
