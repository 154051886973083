import { createContext } from 'react'
import { IPageBuilderData } from './types'

interface IPageBuilderContext extends Partial<IPageBuilderData> {
  pageId: string
}

export const PageBuilderContext = createContext<IPageBuilderContext>({
  pageId: '',
  hero: {
    id: '',
    __component: '',
  },
  sections: [],
})
