import styled from 'styled-components'

export const TitleWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  --text-color: #000;
`
