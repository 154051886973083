import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  .react-player > div > div {
    height: 100%;
    aspect-ratio: 16/9;
    width: 100%;
  }
`
