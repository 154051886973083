import styled, { css } from 'styled-components'

export const gridColumns = (col: number, gap: string) => css`
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(100% / ${col} - ${gap} * (${col} - 1) / ${col}), 1fr)
  );

  grid-gap: ${gap};
`

export const BrandsWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;

  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;

  ${gridColumns(5, '40px')}

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    ${gridColumns(2, '20px')}
  }
`

export const BrandImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 160px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
