import React from 'react'
import { ColorTheme, Wrapper } from './styles'

import { Link } from 'gatsby'

export interface PageBuilderSectionProps {
  className?: string
  data?: any
  overlay?: boolean
  noPadding?: boolean
  section: React.FC<any>
  theme?: ColorTheme
}

export const PageBuilderSection: React.FC<PageBuilderSectionProps> = ({
  className,
  section,
  data,
  overlay = false,
  noPadding = false,
  theme,
}) => {
  const Section = section

  if (!Section) {
    return null
  }

  const link = data.link

  const Content = (
    <Wrapper
      $overlay={overlay}
      $noPadding={noPadding}
      $theme={theme}
      className={`section ${className ?? ''}`}
    >
      <Section data={data} />
    </Wrapper>
  )

  return link ? <Link to={link}>{Content}</Link> : <>{Content}</>
}
