import styled, { css } from 'styled-components'

export const VideoWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: 100%;
  aspect-ratio: 16/9;
`
