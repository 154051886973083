export const mapColumns = {
  'Size 1/1': '1fr 1fr',
  'Size 2/1': '1.5fr 1fr',
  'Size 3/1': '2.5fr 1fr',
  'Size 1/1/1': '1fr 1fr 1fr',
  'Size 2/1/1': '2fr 1fr 1fr',
  'Single 1/1': '1fr 1fr',
} as const

export type TGridColumns = keyof typeof mapColumns
