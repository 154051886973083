/** @jsx jsx */
import { Button, jsx } from 'theme-ui'
import Chevron from '~/components/icons/chevron'

const SliderArrowCustom = ({ position = 'left', sx = {}, ...rest }): JSX.Element => {
  return (
    <Button
      variant="noStyle"
      sx={{
        position: 'absolute',
        top: '50%',
        transform: `translateY(-50%)`,
        left: position === 'left' && ['2em', null, '5em'],
        right: position === 'right' && ['2em', null, '5em'],
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.2)',
        padding: 0,
        width: 55,
        height: 80,
        svg: {
          width: ['1rem', '1.3rem'],
        },
        ...sx,
      }}
      {...rest}
    >
      <Chevron direction={position} width="4rem" height="4rem" />
    </Button>
  ) as JSX.Element
}

export default SliderArrowCustom
