/** @jsx jsx */
import { jsx } from 'theme-ui'

import { PagePropsInterface } from '~/@types/models'

import PageBuilder from '~/componentsV2/pageBuilder'
import { IPageBuilderData } from '~/componentsV2/pageBuilder/types'

const Homepage = (props: PagePropsInterface<IPageBuilderData>) => {
  const data = props?.pageContext?.data

  return <PageBuilder pageId="home-page" preloadedData={data} />
}

export default Homepage
