import GridSection from './GridSection'
import HeroSection from './HeroSection'
import CardSection from './CardSection'
import TitleSection from './TitleSection'
import SliderSection from './SliderSection'
import BrandsSection from './BrandsSection'
import VideoSection from './VideoSection'

const sections = {
  grid: GridSection,
  hero: HeroSection,
  card: CardSection,
  title: TitleSection,
  slider: SliderSection,
  brands: BrandsSection,
  video: VideoSection,
}

export default sections

export type SectionKeys = keyof typeof sections
