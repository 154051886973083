import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IPageBuilderApiData, IPageBuilderData, IPageBuilderSection } from './types'
import { PageBuilderContext } from './context'
import Layout, { LayoutProps } from '~/components/layout'
import { graphql, useStaticQuery } from 'gatsby'
import { QueryResult } from '~/components/seo/types'
import { parseCmsData } from './utils'
import { cmsEnv } from '~/common/cms'
import { PageContent, PageWrapper } from './styles'
import { PageBuilderSection } from './components/PageBuilderSection'
import homeSections, { SectionKeys } from './sections'
import HeroSlider from './components/HeroSlider'
import { useIdentifierModal } from '~/context/identifier-modal'

interface PageBuilderProviderProps {
  pageId: string
  preloadedData?: IPageBuilderData
  layoutProps?: Partial<LayoutProps>
}

// eslint-disable-next-line @typescript-eslint/promise-function-async, @typescript-eslint/no-explicit-any
export const loadData = async (pageId: string, isDraft: boolean): Promise<IPageBuilderApiData> => {
  const res = await fetch(
    `${cmsEnv.endpoint}/${pageId}?populate=deep${isDraft ? '&draft=true' : ''}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${cmsEnv.token}`,
      },
    }
  )
  return (await res.json()).data
}

export const PageBuilder: React.FC<PageBuilderProviderProps> = ({
  layoutProps,
  preloadedData,
  pageId,
}) => {
  const [data, setData] = useState<IPageBuilderData | undefined>(() => {
    return preloadedData ? parseCmsData(preloadedData) : undefined
  })
  const { customerSegment } = useIdentifierModal()

  const parseSegmentContent = useCallback(
    (content: IPageBuilderSection[]) => {
      return (
        content.filter(
          item =>
            !item.visibility || item.visibility === customerSegment || item.visibility === 'all'
        ) || []
      )
    },
    [customerSegment]
  )

  const { hero, sections } = useMemo(() => {
    if (!data) {
      return { hero: [], sections: [] }
    }

    const { hero: _hero, sections: _sections } = data

    const hero = parseSegmentContent(_hero)
    const sections = parseSegmentContent(_sections)

    return { hero, sections }
  }, [data])

  useEffect(() => {
    // query selector draft
    const draft = window.location.search.includes('draft=true')

    if (!preloadedData || draft) {
      loadData(pageId, draft).then(data => {
        setData(parseCmsData<IPageBuilderData>(data))
      })
    }
  }, [preloadedData, pageId])

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          defaultImage
          defaultDescription: description
        }
      }
    }
  `)

  const image = query.site.siteMetadata.defaultImage
  const description = query.site.siteMetadata.defaultDescription

  if (!data) {
    return null
  }

  return (
    <PageWrapper>
      <PageBuilderContext.Provider value={{ pageId, ...data }}>
        <Layout
          headerVariant="transparent"
          seoTitle="Ben Soleimani | Rugs & Furniture | Iconic Design | Bespoke Quality"
          description={description}
          image={image}
          topBanner={true}
          stickyMenu={true}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            gap: [20, 35],
          }}
          {...layoutProps}
        >
          <PageContent>
            {!!hero?.length && <HeroSlider items={hero} />}
            {sections.map(section => {
              const type = section.__component.split('.').pop() as SectionKeys
              const Section = homeSections[type]
              return (
                <PageBuilderSection overlay section={Section} data={section} key={section.id} />
              )
            })}
          </PageContent>
        </Layout>
      </PageBuilderContext.Provider>
    </PageWrapper>
  )
}

export default PageBuilder
