import React, { useMemo } from 'react'

import { VideoWrapper } from './styles'
import ResposiveVideo from '../../../ResposiveVideo'

export interface VideoSectionProps {
  data: {
    desktopVimeoId: string
    mobileVimeoId: string
  }
}

const VideoSection: React.FC<VideoSectionProps> = props => {
  const { desktopVimeoId, mobileVimeoId } = props.data

  return (
    <VideoWrapper className="video-section">
      <ResposiveVideo
        desktopId={desktopVimeoId}
        mobileId={mobileVimeoId}
        playerOptions={{
          controls: true,
          autoplay: false,
        }}
      />
    </VideoWrapper>
  )
}

export default VideoSection
