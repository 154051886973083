import React, { useMemo } from 'react'

import { TitleWrapper } from './styles'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import BlockTextRender from '../../components/BlockTextRender'
import { DeviceControl } from '../../styles'
import { TDevice } from '../../types'

export interface TitleSectionProps {
  data: {
    title: BlocksContent
    device: TDevice
  }
}

const TitleSection: React.FC<TitleSectionProps> = props => {
  const { title, device } = props.data

  if (!title) {
    return null
  }

  return (
    <DeviceControl $device={device}>
      <TitleWrapper className="title-section">
        <BlockTextRender content={title} />
      </TitleWrapper>
    </DeviceControl>
  )
}

export default TitleSection
