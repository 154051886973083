import React from 'react'
import { useTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import ReactPlayer from 'react-player'
import { Wrapper } from './styles'

export interface ResposiveVideoProps {
  desktopId: string
  mobileId: string
  playerOptions?: Record<string, any>
  muted?: boolean
  loop?: boolean
}

const ResposiveVideo: React.FC<ResposiveVideoProps> = ({
  desktopId,
  mobileId,
  playerOptions,
  muted = false,
  loop = false,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery({ query: theme.mediaQueries.maxMd })

  if (!isMobile && !desktopId) {
    return null
  }

  const videoId = isMobile ? mobileId : desktopId

  return (
    <Wrapper>
      <ReactPlayer
        url={`https://vimeo.com/${videoId || desktopId}`}
        width="100%"
        height="100%"
        loop={loop}
        muted={muted}
        className="react-player"
        config={{
          vimeo: {
            playerOptions: {
              ...playerOptions,
              responsive: true,
            },
          },
        }}
      />
    </Wrapper>
  )
}

export default ResposiveVideo
