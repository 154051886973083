import styled from 'styled-components'
import { CardWrapper } from '../CardSection/styles'

export const SliderWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: 100%;

  ${({ theme }) => '@media ' + theme.mediaQueries.mobile} {
    ${CardWrapper} {
      min-height: auto;
    }
  }
`
